@import "src/app/styles/variables";

.unauthorised {
    opacity: .5;

    &--cursor-not-allowed {
        cursor: not-allowed !important;
    }

    &--hover-background-color-grey {
        &:hover {
            color: $secondary-gray-lighter !important;
        }
    }

    &:not(.unauthorised--no-lock-icon)::after {
        display: inline-block;
        width: 14px !important;
        min-width: 14px !important;
        height: 14px !important;
        margin-left: 4px;
        background-color: $black;
        content: "";
        mask-image: $lock-closed-icon;
    }

    &--position-relative {
        position: relative !important;
    }

    &--opacity-full {
        opacity: 1 !important;
    }

    &--bg-default::after {
        background-color: $secondary-gray-dark !important;
    }

    &--bg-unset::after {
        background-color: $secondary-gray-dark !important;
        opacity: .5;
    }

    &--light {
        color: $black !important;

        &::after {
            background-color: $black;
        }
    }

    &--dark::after {
        background-color: $secondary-gray-lighter !important;
    }

    &__main-menu:not(.unauthorised__main-menu--no-lock-icon)::after {
        position: absolute;
        top: 50%;
        right: 16px;
        width: 14px;
        min-width: 14px;
        height: 14px;
        margin: 0;
        background-color: $secondary-gray-dark;
        content: "";
        mask-image: $lock-closed-icon;
        transform: translateY(-50%);
    }

    &__nav-tab:not(.unauthorised__nav-tab--no-lock-icon)::after {
        position: absolute;
        top: 0;
        right: 16px;
        width: 14px;
        min-width: 14px;
        height: 14px;
        margin: 0;
        background-color: $secondary-gray-dark;
        content: "";
        mask-image: $lock-closed-icon;
    }

    &__create-ptf:not(.unauthorised__create-ptf--no-lock-icon)::after {
        width: 18px;
        min-width: 18px;
        height: 18px;
        margin: 0 auto;
        background-color: $secondary-gray-dark;
        content: "";
        mask-image: $lock-closed-icon;
    }

    &__subsegmentation > * {
        opacity: .5;
    }

    &__subsegmentation::after {
        margin-bottom: 2px;
        opacity: .5;
    }
}

.icon--unauthorised {
    filter: saturate(0);
}
