@import "./variables";
@import "./mixins";

.little-card {
    position: relative;
    display: flex;
    height: 100px;
    justify-content: space-between;
    border-radius: 20px;
    background-color: $white;
    box-shadow: 0 0 4px rgb(0 0 0 / .1);

    &--is-benchmark {
        border: 6px solid $secondary-gray-light;
        border-radius: 28px;
        margin-top: -3px;

        &::before {
            position: absolute;
            border: 3px dashed $primary-passive-main;
            border-radius: 28px;
            content: "";
            inset: -10px;
            opacity: .75;
        }
    }

    &__benchmark {
        position: absolute;
        top: -24px;
        left: 50%;
        padding: 4px 16px;
        border-radius: 12px 12px 0 0;
        background: $primary-gradient-passive-main;
        transform: translateX(-50%);
    }

    &__icon-delete {
        position: absolute;
        top: -12px;
        left: -10px;
        width: 2rem;
        height: 2rem;
        color: $white;
        cursor: pointer;
        opacity: .5;
        transition: .15s ease-in-out;

        circle {
            fill: $primary-active-light;
        }
    }

    &:hover .little-card__icon-delete {
        z-index: 1;
        opacity: 1;
    }

    &__legend {
        width: 40px;
        min-width: 40px;
        height: 8px;
        border: 1px solid $secondary-gray-light;
        border-radius: .5rem;
    }

    &__left {
        display: flex;
        max-width: 42px;
        align-items: center;
        justify-content: center;
        border-radius: 20px 0 0 20px;
        background-color: $secondary-gray-main;

        &-content {
            display: flex;
            max-height: 42px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 6px;
            transform: rotate(-90deg);

            eb-label {
                text-align: center;
                white-space: nowrap;
            }
        }
    }

    &__middle {
        position: relative;
        display: flex;
        flex: 1;
        flex-shrink: 0;
        align-items: center;
        padding: 5px 0;

        .assets {
            display: flex;
            align-items: center;
        }

        .logo {
            position: absolute;
            left: 12px;
            width: 48px;
            height: 48px;
            border-radius: 8px;

            &--no-src {
                display: flex;
                box-sizing: border-box;
                align-items: center;
                justify-content: center;
                border: 1px solid $secondary-gray-main;
                background-color: $secondary-gray-lighter;
                opacity: .5;
                text-transform: uppercase;
            }

            &--client {
                display: flex;
                box-sizing: border-box;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                border: 1px solid $primary-active-main;
                color: $primary-active-main;
                font-size: 16px;
                font-weight: 800;
                opacity: .5;
                text-align: center;
                text-transform: uppercase;
                word-break: break-word;
            }

            &--client-no-name {
                align-items: flex-end;
            }
        }

        .content {
            display: flex;
            min-width: calc(100% - 14px);
            height: 100%;
            flex-direction: column;
            justify-content: space-between;

            &--center {
                justify-content: center;
            }
        }

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: 14px;

            .icon {
                width: 24px;
                height: 24px;
                margin-right: 4px;
            }

            .icon--sfdr {
                width: 32px;
                height: 20px;
            }

            eb-label {
                min-height: 14px;
            }
        }

        .header-name {
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            text-overflow: ellipsis;
        }

        .titles {
            margin-left: 70px;
            cursor: pointer;

            .label {
                display: -webkit-box;
                overflow: hidden;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                line-height: inherit;
                text-overflow: ellipsis;
            }

            .sub-label {
                display: -webkit-box;
                overflow: hidden;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                text-overflow: ellipsis;
            }
        }
    }

    &__right {
        display: flex;
        height: 100%;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;

        eb-indicator-line-wrapper {
            width: auto;
            height: 92px;
            border: none;
        }
    }

    &__footer {
        min-height: 16px;
    }
}
