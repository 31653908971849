@import "src/app/styles/variables";

.nav-tab {
    display: flex;
    height: 36px;
    padding-left: 60px;
    background-color: $secondary-gray-light;

    &-number {
        margin-left: 8px;
        letter-spacing: .05em;
        opacity: .5;
    }

    &-close {
        width: 16px;
        border: 1px solid $secondary-gray-light;
        border-radius: 50%;
        margin-left: 8px;
        color: $secondary-gray-dark;

        &:hover {
            border-color: $secondary-gray-dark;
            color: $black;
        }
    }

    &-link {
        position: relative;
        display: flex;
        align-items: center;
        padding: 9px 20px;
        cursor: pointer;
        opacity: .5;
        text-decoration: none;

        &--active,
        &:hover {
            background-color: $white;
            opacity: 1;
        }

        &--active eb-label {
            color: $black;
        }

        &--active::before,
        &:hover::before {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 48px;
            height: 2px;
            background-color: $primary-active-main;
            content: "";
            transform: translateX(-50%);
        }

        &:hover eb-label {
            color: $black;
        }

        &--unauthorised {
            color: $secondary-gray-dark;
        }

        &:hover {
            .nav-tab-close {
                opacity: 1;
            }
        }
    }
}
