@keyframes select-input-fade {
    0% {
        opacity: 0;
        transform: translateY(-5px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* stylelint-disable keyframe-block-no-duplicate-selectors */
@keyframes bounce-in {
    0%,
    20%,
    40%,
    60%,
    80%,
    100% {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    }

    0% {
        opacity: 0;
        transform: scale3d(.3, .3, .3);
    }

    20% {
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        transform: scale3d(.9, .9, .9);
    }

    60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        transform: scale3d(.97, .97, .97);
    }

    100% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}
/* stylelint-enable keyframe-block-no-duplicate-selectors */

@keyframes rotate-full {
    from {
        opacity: 1;
    }

    to {
        opacity: 1;
        transform: rotate3d(0, 0, 1, 360deg);
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}

@keyframes pulse {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.01);
    }

    100% {
      transform: scale(1);
    }
}

@keyframes pulsate-wave {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(1, 1);
    }

    60% {
        opacity: 0.7;
    }

    80% {
        opacity: 0.1;
    }

    100% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(1.04, 1.24);
    }
}
