@import "src/app/styles/variables";

.range {
    display: flex;
    gap: 8px;

    &__line {
        display: flex;
        flex-direction: column;
    }

    &__btn-operator {
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
    }

    &__refresh {
        width: 20px;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 12px;
        cursor: pointer;
    }

    &__container-input {
        position: relative;
    }

    &__input {
        width: 80px;
        height: 30px;
        padding-right: 30px;
        border: 1px solid $secondary-gray-light;
        border-radius: 4px;
        font-size: 12px;
        font-weight: bold;
        text-align: right;

        &--reduced {
            width: 64px;
        }

        &--no-prefix {
            width: 100px;
            padding-right: 10px;

            &.range__input--reduced {
                width: 84px;
            }
        }
    }

    &__suffix {
        position: absolute;
        top: 52%;
        right: 10px;
        transform: translateY(-50%);
    }

    &__reset-icon {
        padding-top: 14px;
        cursor: pointer;
    }
}
