.grid-system {
    &__col {
        box-sizing: content-box;

        &--1 {
            width: 60px;
        }

        &--2 {
            width: 120px;
        }

        &--3 {
            width: 180px;
        }

        &--4 {
            width: 240px;
        }

        &--5 {
            width: 300px;
        }

        &--6 {
            width: 360px;
        }

        &--7 {
            width: 420px;
        }

        &--8 {
            width: 480px;
        }

        &--9 {
            width: 520px;
        }

        &--10 {
            width: 83.3333%;
        }

        &--11 {
            width: 91.6666%;
        }

        &--12 {
            width: 100%;
        }
    }
}
