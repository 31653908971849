@import "./variables";
@import "./mixins";

$card-height: 250px;
$card-width: 272px;
$card-video-width: 400px;
$card-padding-t: 16px;
$card-padding-b: 10px;
$card-padding-x: 24px;
$border-card: 1px;

.card-list {
    position: relative;
    display: flex;
    width: $card-video-width - ( $border-card * 2 ) - ( $card-padding-x * 2 );
    height: $card-height - ( $border-card * 2 ) - $card-padding-b - $card-padding-t;
    flex-direction: column;
    justify-content: space-between;
    padding: $card-padding-t $card-padding-x $card-padding-b $card-padding-x;
    border: $border-card solid $secondary-gray-light;
    border-radius: 20px;
    margin-top: 12px; // Draggable Container
    background-color: $white;

    &__icon-selected {
        position: absolute;
        top: -8px;
        left: -8px;
        width: 2rem;
        height: 2rem;
        cursor: pointer;

        &--not-selected {
            opacity: .8;
            transition: .15s ease-in-out;
        }

        &--disabled {
            display: none;
        }
    }

    &:hover .card-list__icon-selected--not-selected { opacity: 1; }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
            width: 48px;
            height: 48px;
            border-radius: 8px;
            margin-right: 6px;

            &--no-src {
                display: flex;
                box-sizing: border-box;
                align-items: center;
                justify-content: center;
                border: 1px solid $secondary-gray-main;
                background-color: $secondary-gray-lighter;
                opacity: .5;
                text-transform: uppercase;
            }
        }

        .labels {
            display: inline-flex;
            max-height: 24px;
            align-items: center;
            vertical-align: middle;
        }

        .icon {
            width: 24px;
            height: 24px;
            margin-right: 4px;

            &--sfdr {
                width: 32px;
                height: 20px;
            }
        }

        .menu {
            display: inline-block;
            width: 32px;
            height: 32px;
            margin-right: -8px;
            cursor: pointer;
            vertical-align: middle;
        }
    }

    &__tag-container {
        position: absolute;
        top: -12px;
        left: 24px;
        display: flex;
        gap: 10px;
    }

    &__tag {
        padding: 0 24px;
        border-radius: 5px 5px 0 0;
        background: linear-gradient(180deg, rgb(142 125 19 / 0) 0%, rgb(142 125 19 / .15) 100%), $white;
        box-shadow: 0 0 2px rgb(0 0 0 / .2);
        font-size: 8px;
        font-weight: 700;
        line-height: 12px;
        text-transform: uppercase;

        &--gold {
            background: linear-gradient(180deg, rgba($primary-active-main-rgb / 0) 0%, rgba($primary-active-main-rgb / .3) 100%), $white;
        }
    }

    &__titles {
        .label {
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            cursor: pointer;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            white-space: break-spaces;
        }
    }

    &__company-name {
        white-space: break-spaces;
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        eb-indicator-card-list { height: 75px; }

        .video {
            display: block;
            overflow: hidden;
            width: 120px;
            height: 75px;
            border-radius: 8px;
            margin-left: 1.4rem;

            &--empty {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $secondary-gray-main;
                background-color: $secondary-gray-ultra-light;
            }
        }
    }

    &__footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}
