@import "./variables";
@import "./mixins";

.line-list {
    position: relative;
    margin-bottom: 20px;

    &--is-benchmark {
        border: 6px solid #eeedea;
        border-radius: 28px;
        margin-right: -6px;
        margin-left: -6px;

        &::before {
            position: absolute;
            border: 3px dashed var(--primary-passive-main);
            border-radius: 28px;
            content: "";
            inset: -10px;
            opacity: .75;
        }
    }

    &__benchmark {
        position: absolute;
        top: -24px;
        left: 50%;
        padding: 4px 16px;
        border-radius: 12px 12px 0 0;
        background: $primary-gradient-passive-main;
        transform: translateX(-50%);
    }

    &__background {
        position: relative;
        display: flex;
        height: 110px;
        border-radius: 20px;
        background-color: $white;
        box-shadow: 0 0 4px rgb(0 0 0 / .1);
    }

    &__icon-selected {
        position: absolute;
        z-index: 100;
        top: -10px;
        left: -15px;
        width: 2rem;
        height: 2rem;
        cursor: pointer;

        &--not-selected {
            opacity: .8;
            transition: .15s ease-in-out;
        }

        &--disabled {
            display: none;
        }
    }

    &__tag-container {
        position: absolute;
        top: -12px;
        left: 24px;
        display: flex;
        gap: 10px;
    }

    &__tag {
        padding: 0 24px;
        border-radius: 5px 5px 0 0;
        background: $primary-gradient-active-light;
        box-shadow: 0 0 2px rgb(0 0 0 / .2);
        font-size: 8px;
        font-weight: 700;
        line-height: 12px;
        text-transform: uppercase;

        &--gold {
            background: linear-gradient(180deg, rgba($primary-active-main-rgb / 0) 0%, rgba($primary-active-main-rgb / .3) 100%), $white;
        }
    }

    &:hover .line-list__icon-selected--not-selected { opacity: 1; }

    &__middle {
        overflow: hidden;
        width: 100%;

        eb-indicator-line-list {
            display: block;
            width: 100%;
            height: 100%;
            margin-top: 3px;
            margin-right: -12px;
            margin-left: 4px;
        }
    }

    &__position-background {
        display: flex;
        width: 60px;
        min-width: 60px;
        align-items: center;
        justify-content: center;
        background: $primary-gradient-passive-main;
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
        color: #fff;
        font-size: 40px;
    }

    &__position-text {
        background: $line-list-position-text-color;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &__entity-container {
        display: flex;
        max-width: 42px;
        align-items: center;
        justify-content: center;
        border-radius: 20px 0 0 20px;
        background-color: $secondary-gray-main;
    }

    &__entity-content {
        display: flex;
        max-height: 42px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 6px;
        transform: rotate(-90deg);

        eb-label {
            text-align: center;
        }
    }

    &__entity-legend {
        width: 40px;
        min-width: 40px;
        height: 8px;
        border: 1px solid $secondary-gray-light;
        border-radius: .5rem;
    }

    &__position-background + &__entity-container {
        border-radius: 0;
    }

    &__left {
        position: relative;
        flex-shrink: 0;
        padding: .5rem 0;

        &-background {
            display: flex;
            height: 100%;
            align-items: center;

            &::after {
                width: 10px;
                height: 70px;
                margin-left: .25rem;
                box-shadow: 2px 0 5px -2px rgb(0 0 0 / .2);
                content: "";
            }
        }

        .assets {
            display: flex;
            align-items: center;
        }

        .logo {
            position: absolute;
            left: 12px;
            width: 48px;
            height: 48px;
            border-radius: 8px;

            &--no-src {
                display: flex;
                box-sizing: border-box;
                align-items: center;
                justify-content: center;
                border: 1px solid $secondary-gray-main;
                background-color: $secondary-gray-lighter;
                opacity: .5;
                text-transform: uppercase;
            }
        }

        .content {
            display: flex;
            width: 370px;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;
        }

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: 14px;

            .icon {
                width: 24px;
                height: 24px;
                margin-right: 4px;
            }

            .icon--sfdr {
                width: 32px;
                height: 20px;
            }

            eb-label {
                min-height: 14px;
            }
        }

        .titles {
            margin-left: 70px;
            cursor: pointer;

            .label {
                display: -webkit-box;
                overflow: hidden;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                text-overflow: ellipsis;
            }
        }
    }

    &__right {
        min-width: 216px;
        flex-shrink: 0;

        &-background {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: space-between;

            &::before {
                width: 10px;
                height: 70px;
                box-shadow: -2px 0 5px -2px rgb(0 0 0 / .2);
                content: "";
            }
        }

        .video {
            overflow: hidden;
            width: 120px;
            height: 68px;
            border-radius: 8px;

            &--empty {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $secondary-gray-main;
                background-color: $secondary-gray-ultra-light;
            }
        }
    }

    &__footer {
        min-height: 16px;
    }
}

@media print {
    .line-list {
        page-break-inside: avoid;
    }
}
