@use "sass:map";

@import "./variables";

.mat-mdc-snack-bar-container {
    --mat-mdc-snack-bar-button-color: #fff;
    --mat-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;

    max-width: 440px;

    &.snackbar-error,
    &.snackbar-info,
    &.snackbar-warning,
    &.snackbar-success {
        button {
            border: 2px solid #fffc;
            margin-left: 16px;
            background: none;

            &:hover {
                background-color: #ffffffe6;
            }
        }
    }

    &.snackbar-error {
        --mdc-snackbar-container-color: #ff0000b3; // contextual-colors red-alert 500 .7

        button:hover {
            --mat-snack-bar-button-color: #ff0000b3;
        }

        &:hover {
            --mdc-snackbar-container-color: #f00c; // contextual-colors red-alert 500 1
        }
    }

    &.snackbar-info {
        --mdc-snackbar-container-color: #3b82f6b3; // contextual-colors info 500 .7

        &:hover {
            --mdc-snackbar-container-color: #3b82f6cc; // contextual-colors info 500 1
        }

        button:hover {
            --mat-snack-bar-button-color: #3b82f6b3;
        }
    }

    &.snackbar-success {
        --mdc-snackbar-container-color: #10b981b3; // contextual-colors success 500 .7

        &:hover {
            --mdc-snackbar-container-color: #10b981cc; // contextual-colors success 500 1
        }

        button:hover {
            --mat-snack-bar-button-color: #10b981b3;
        }
    }

    &.snackbar-warning {
        --mdc-snackbar-container-color: #f97316b3; // contextual-colors warning 500 .7

        &:hover {
            --mdc-snackbar-container-color: #f97316cc; // contextual-colors warning 500 1
        }

        button:hover {
            color: rgba(map.get(map.get($contextual-colors, "warning"), "500"), .7) !important;
        }
    }
}
