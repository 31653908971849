@import "src/app/styles/variables";

.market-cycle-overall:not(eb-icon) {
    color: $overall;
}

eb-icon.market-cycle-overall {
    svg {
        path.primary-stroke {
            stroke: $overall;
        }

        path.primary-fill {
            fill: $overall;
        }
    }
}

.market-cycle-stable:not(eb-icon) {
    color: $stable;
}

eb-icon.market-cycle-stable {
    svg {
        path.primary-stroke {
            stroke: $stable;
        }

        path.primary-fill {
            fill: $stable;
        }
    }
}

.market-cycle-unstable:not(eb-icon) {
    color: $unstable;
}

eb-icon.market-cycle-unstable {
    svg {
        path.primary-stroke {
            stroke: $unstable;
        }

        path.primary-fill {
            fill: $unstable;
        }
    }
}

.market-cycle-crisis:not(eb-icon) {
    color: $crisis;
}

eb-icon.market-cycle-crisis {
    svg {
        path.primary-stroke {
            stroke: $crisis;
        }

        path.primary-fill {
            fill: $crisis;
        }
    }
}

.market-cycle-recovery:not(eb-icon) {
    color: $recovery;
}

eb-icon.market-cycle-recovery {
    svg {
        path.primary-stroke {
            stroke: $recovery;
        }

        path.primary-fill {
            fill: $recovery;
        }
    }
}
