@import "src/app/styles/variables";

@keyframes app-loader-spinner {
    to {
        transform: rotate(360deg);
    }
}

.app-loader {
    position: absolute;
    z-index: 999;
    top: 0;
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;

    &__spinner {
        --animation-duration: 650ms;

        position: relative;
        width: 75px;
        height: 75px;

        &--small {
            width: 50px;
            height: 50px;
        }

        &-item {
            position: absolute;
            top: calc(50% - var(--item-size) / 2);
            left: calc(50% - var(--item-size) / 2);
            width: var(--item-size);
            height: var(--item-size);
            border: 4px solid transparent;
            border-radius: 50%;
            border-right: 4px solid var(--clr-spinner);
            border-left: 4px solid var(--clr-spinner);
            animation: app-loader-spinner var(--animation-duration) linear infinite;

            &:nth-of-type(1) {
                --item-size: 75px;
                --clr-spinner: #{$primary-active-main};

                border-top: 4px solid var(--clr-spinner);
            }

            &:nth-of-type(2) {
                --item-size: 60px;
                --clr-spinner: #{$primary-active-light};

                border-bottom: 4px solid var(--clr-spinner);
            }

            &:nth-of-type(3) {
                --item-size: 45px;
                --clr-spinner: #{$primary-active-dark};

                border-top: 4px solid var(--clr-spinner);
            }
        }
    }
}
