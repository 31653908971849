@import "src/app/styles/variables";

.classic-input-round-bg {
    padding: .5rem 1rem;
    border: none;
    border-radius: 12px;
    background: $secondary-gray-light;
    font-size: .875rem;
    outline: none;
}
